import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../../components/Navbar"
import Alerts from '../../../components/Alerts';

//styles
import './Novo.scss';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import ReaisInput from '../../../components/ReaisInput';

import balistica01 from './img/balistica01.png';
import balistica02 from './img/balistica02.png';
import balistica03 from './img/balistica03.png';
import balistica04 from './img/balistica04.png';
import balistica05 from './img/balistica05.png';
import balistica06 from './img/balistica06.png';

import armamento01 from './img/armamento01.png';
import armamento02 from './img/armamento02.png';
import armamento03 from './img/armamento03.png';
import armamento04 from './img/armamento04.png';
import armamento05 from './img/armamento05.png';
import armamento06 from './img/armamento06.png';

//Imagens 
import logo from '../../Dashboard/img/logo-idzcar.png'

function Proposta() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [menu, setMenu] = useState(false);
    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    const navigate = useNavigate();
    // Recebendo Dados
    const { id } = useParams();

    const location = useLocation();
    const { idProposta, dadosProposta } = location.state || {};  // Certifique-se de que isso está correto

    useEffect(() => {
        if (!id) {
            console.error('ID da proposta não encontrado');
        } else {
            console.log('ID da proposta:', id);
        }
    }, [id]);


    const { token, user, loading } = useAuth();

    // Alertas 

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    // Estados de navegação entre as seções
    const [intVeiculo, setVeiculo] = useState(true);
    const [intComprador, setComprador] = useState(false);
    const [intVendedor, setVendedor] = useState(false);
    const [intInformacoes, setInformacoes] = useState(false);
    const [intPagamento, setPagamento] = useState(false);

    // Estados de dados do veículo
    const [marcas, setMarcas] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [anosModelo, setAnosModelo] = useState([]);
    const [anosModeloDetalhes, setAnosModeloDetalhes] = useState([]);
    const [anoFabricacao, setAnoFabricacao] = useState([]);

    // Estados de seleção do veículo
    const [selectedMarca, setSelectedMarca] = useState(null);
    const [selectedModelo, setSelectedModelo] = useState(null);
    const [selectedAnoModelo, setSelectedAnoModelo] = useState(null);
    const [selectedAnoModeloDetalhes, setSelectedAnoModeloDetalhes] = useState(null);

    // Tipos de Blindagem (array fixo)
    const tiposBlindagem = [{ label: "UDURA", value: "UDURA" }];
    const [selectedBlindagem, setSelectedBlindagem] = useState(tiposBlindagem[0].value);

    // Estados para blindagem, vidros e garantia
    const [blindagem, setBlindagem] = useState([]);
    const [vidros, setVidros] = useState([]);
    const [garantia, setGarantia] = useState([]);
    const [selectedVidro, setSelectedVidro] = useState(null);
    const [selectedGarantia, setSelectedGarantia] = useState(null);

    // Funções de busca de dados do veículo
    const getMarcas = async () => {
        try {
            const response = await api.get(`/api/marcas`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const lista = response.data
                    .filter(marca => marca.status === "Ativo")
                    .map(marca => ({ label: marca.titulo, value: marca.id }));
                setMarcas(lista);
            }
        } catch (error) {
            console.error("Erro ao buscar marcas:", error);
        }
    };

    const getModelos = async (marca_id) => {
        try {
            const response = await api.get(`/api/modelos/marca/${marca_id}?origem=blindados&agrupado=1`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const lista = response.data
                    .filter(modelo => modelo.status === "Ativo")
                    .map(modelo => ({ label: modelo.titulo, value: modelo.id }));
                setModelos(lista);
                return lista;
            }
        } catch (error) {
            console.error("Erro ao buscar modelos:", error);
            return [];
        }
    };

    const getAnosModelo = async (modelo_id) => {
        try {
            const response = await api.get(`/api/modelos/${modelo_id}/anos?modelo=base`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const lista = response.data.map(ano => ({ label: ano, value: ano }));
                setAnosModelo(lista);
            }
        } catch (error) {
            console.error("Erro ao buscar anos do modelo:", error);
        }
    };

    const getModelosDetalhes = async (modelo_id, ano) => {
        try {
            const response = await api.get(`/api/modelos/${modelo_id}/ano/${ano}`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const anosModeloDetalhesList = response.data.map(modelo => ({
                    label: modelo.anoModelo,
                    value: modelo.anoModelo
                }));
                setAnosModeloDetalhes(anosModeloDetalhesList);
            }
        } catch (error) {
            console.error("Erro ao buscar detalhes do modelo:", error);
        }
    };

    // Funções de busca de dados da blindagem, vidros e garantia
    const getBlindagem = async () => {
        try {
            const response = await api.get(`/api/atributos?atributo=tipo_blindagem`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const lista = response.data.opcoes.map(opcao => ({
                    label: opcao.label,
                    value: opcao.id
                }));
                setBlindagem(lista);
            }
        } catch (error) {
            console.error("Erro ao buscar blindagem:", error);
        }
    };

    const getGarantia = async () => {
        try {
            const response = await api.get(`/api/atributos?atributo=garantia_vidro`, {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });
            if (response.data) {
                const lista = response.data.opcoes.map(opcao => ({
                    label: opcao.label,
                    value: opcao.id
                }));
                setGarantia(lista);
            }
        } catch (error) {
            console.error("Erro ao buscar garantias:", error);
        }
    };

    // Efeitos para carregar os dados ao montar o componente
    useEffect(() => {
        getMarcas();
        getBlindagem();
        getGarantia();
    }, []);

    // Efeito para preencher ano de fabricação baseado no ano selecionado
    useEffect(() => {
        if (selectedAnoModelo) {
            setAnoFabricacao([
                { label: selectedAnoModelo, value: selectedAnoModelo },
                { label: selectedAnoModelo + 1, value: selectedAnoModelo + 1 }
            ]);
        }
    }, [selectedAnoModelo]);

    // Funções de manipulação de eventos
    const handleMarcaChange = (e) => {
        setSelectedMarca(e.value);
        setSelectedModelo(null);
        setSelectedAnoModelo(null);
        setModelos([]);
        setAnosModelo([]);
        getModelos(e.value);
    };


    const handleAnoFabricaChange = (e) => {
        setSelectedAnoModelo(e.value);
        getModelosDetalhes(selectedModelo, e.value);
    };

    // Estados para especificações e opções selecionadas
    const [especificacoes, setEspecificacoes] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [checkboxState, setCheckboxState] = useState({});

    // Função para buscar as especificações de blindagem com base no modelo selecionado
    const getBlindagemEspecificacoes = async (modelo_id) => {
        try {
            const response = await api.get(`/api/blindagem-especificacao?modelo=${modelo_id}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            });
            if (response.data && response.data.especificacoes) {
                setEspecificacoes(response.data.especificacoes);  // Ainda define o estado
                setCategoriaId(response.data.id);  // Salva o ID da categoria
                return response.data.especificacoes;  // Retorna as especificações para uso na cadeia de promises
            } else {
                return null; // Retorne null se não houver especificações
            }
        } catch (error) {
            console.error("Erro ao buscar especificações de blindagem:", error);
            return null; // Retorne null em caso de erro
        }
    };

    // Função para manipular as mudanças nos checkboxes
    const handleCheckboxChange = (especificacaoSku) => {
        setCheckboxState(prevState => ({
            ...prevState,
            [especificacaoSku]: !prevState[especificacaoSku]
        }));
    };

    useEffect(() => {
        // Certifique-se de que não há nenhuma lógica aqui que esteja vinculando os dois estados
 
    }, [checkboxState, selectedOptions]);

    // Função para manipular as mudanças nas opções selecionadas
    const handleOptionChange = (especificacaoSku, value) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [especificacaoSku]: value
        }));
    };

    // Efeito para buscar as especificações de blindagem assim que o modelo for selecionado
    useEffect(() => {
        if (selectedModelo) {
            getBlindagemEspecificacoes(selectedModelo);
        }
    }, [selectedModelo]);

    // Estado para armazenar as opções de vidros

    const handleModeloChange = (e) => {
        setSelectedModelo(e.value);
        getAnosModelo(e.value);
        getBlindagemEspecificacoes(e.value); // Chama as especificações de blindagem assim que o modelo é selecionado
    };

    useEffect(() => {
        especificacoes.forEach((especificacao) => {
            if (especificacao.sku === 'tipo_vidro') {
                // Extrai todos os produtos de todos os arrays dentro de produtos
                const vidrosArray = Object.values(especificacao.produtos).flat();
                setVidros(vidrosArray.map(produto => ({ label: produto.titulo, value: produto.id })));
            }
        });
    }, [especificacoes]);

    const determineGarantia = (especificacoes, vidroId) => {
        let selectedGarantia = '';
        especificacoes.forEach((especificacao) => {
            if (especificacao.sku === 'tipo_vidro') {
                Object.keys(especificacao.produtos).forEach(garantiaKey => {
                    const produtosGarantia = especificacao.produtos[garantiaKey];
                    if (produtosGarantia.some(produto => produto.id === vidroId)) {
                        if (garantiaKey === 'garantia-5') {
                            selectedGarantia = '5 anos';
                        } else if (garantiaKey === 'garantia-10') {
                            selectedGarantia = '10 anos';
                        }
                    }
                });
            }
        });
        return selectedGarantia;
    };

    const handleVidroChange = (value) => {
        setSelectedVidro(value);
        const garantia = determineGarantia(especificacoes, value);
        setSelectedGarantia(garantia);
    };

    // Prazos e Pagamento

    const [selectedValidade, setSelectedValidade] = useState(null);

    const validadeOpcoes = [
        { label: '5 dias', value: 5 },
        { label: '10 dias', value: 10 },
        { label: '20 dias', value: 20 },
        { label: '30 dias', value: 30 },
    ];

    const parcelasOptions = [
        { label: 'À vista', value: 0 },
        { label: '2 vezes', value: 2 },
        { label: '3 vezes', value: 3 },
        { label: '4 vezes', value: 4 },
        { label: '5 vezes', value: 5 },
        { label: '6 vezes', value: 6 },
        { label: '7 vezes', value: 7 },
        { label: '8 vezes', value: 8 },
        { label: '9 vezes', value: 9 },
        { label: '10 vezes', value: 10 },
        { label: '11 vezes', value: 11 },
        { label: '12 vezes', value: 12 }
    ];


    const handlePriceChange = (value) => {
        setValorTotal(value);
    };


    // Validações

    const [invalidFields, setInvalidFields] = useState([]);

    const validateFields = () => {
        const invalids = [];

        // Verificações do veículo (já existentes)
        if (intVeiculo) {
            if (!selectedMarca) invalids.push('marca');
            if (!selectedModelo) invalids.push('modelo');
            if (!selectedAnoModelo) invalids.push('anoFabricacao');
            if (!selectedAnoModeloDetalhes) invalids.push('anoModelo');
            if (!selectedVidro) invalids.push('vidro'); // Adicionei o campo de Tipo de Vidro
        }

        // Verificações do contratante
        if (intComprador) {
            if (!nomeCliente.trim()) invalids.push('nomeCliente');
            if (!telefone.trim()) invalids.push('telefone');
            if (!email.trim()) invalids.push('email');
        }

        setInvalidFields(invalids);

        return invalids.length === 0; // Retorna true se não houver campos inválidos
    };

    const handleContinuar = () => {
        if (validateFields()) {
            if (intVeiculo) {
                setVeiculo(false);
                setComprador(true);  // Passa para a aba do comprador
            } else if (intComprador) {
                setComprador(false);
                setVendedor(true);  // Passa para a aba do vendedor
            } else if (intVendedor) {
                setVendedor(false);
                setInformacoes(true);  // Passa para a aba de informações
            } else if (intInformacoes) {
                setInformacoes(false);
                setPagamento(true);  // Passa para a aba de pagamento
            }
        } else {
            console.log("Preencha todos os campos obrigatórios.");
            handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger');
        }
    };

    const handleBack = () => {
        if (intPagamento) {
            setPagamento(false);
            setInformacoes(true);
        } else if (intInformacoes) {
            setInformacoes(false);
            setVendedor(true);
        } else if (intVendedor) {
            setVendedor(false);
            setComprador(true);
        } else if (intComprador) {
            setComprador(false);
            setVeiculo(true);
        }
    };

    const handleNavigationClick = (section) => {
        if (validateFields()) {
            setVeiculo(false);
            setComprador(false);
            setVendedor(false);
            setInformacoes(false);
            setPagamento(false);

            switch (section) {
                case 'veiculo':
                    setVeiculo(true);
                    break;
                case 'comprador':
                    setComprador(true);
                    break;
                case 'vendedor':
                    setVendedor(true);
                    break;
                case 'informacoes':
                    setInformacoes(true);
                    break;
                case 'pagamento':
                    setPagamento(true);
                    break;
                default:
                    break;
            }
        } else {
            console.log("Preencha todos os campos obrigatórios.");
            handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger');
        }
    };


    // Enviar proposta via POST

    const [categoriaId, setCategoriaId] = useState(null);

    const [nomeCliente, setNomeCliente] = useState('');
    const [cpfCliente, setCpfCliente] = useState('');
    const [rgCliente, setRgCliente] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [endereco, setEndereco] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [cep, setCep] = useState('');

    const [nomeLocalVendedor, setNomeLocalVendedor] = useState('SHOW ROOM');
    const [enderecoVendedor, setEnderecoVendedor] = useState('Alameda dos Guatás, n° 729');
    const [bairroVendedor, setBairroVendedor] = useState('Vila da Saúde');
    const [cidadeVendedor, setCidadeVendedor] = useState('São Paulo');
    const [estadoVendedor, setEstadoVendedor] = useState('SP');
    const [cepVendedor, setCepVendedor] = useState('04053-042');
    const [telefoneVendedor, setTelefoneVendedor] = useState('(11) 5583-1021');
    const [emailVendedor, setEmailVendedor] = useState('contato@1021.com.br');
    const [siteVendedor, setSiteVendedor] = useState('www.1021.com.br');

    const [cnpjContratada, setCnpjContratada] = useState('05.842.957/0001-07');
    const [nomeContratada, setNomeContratada] = useState('TG18 Serviços de Blindagem Ltda');
    const [enderecoContratada, setEnderecoContratada] = useState('Av. Miguel Yunes, 823 B, Galpão 2');
    const [bairroContratada, setBairroContratada] = useState('Usina Piratininga');
    const [cidadeContratada, setCidadeContratada] = useState('São Paulo');
    const [estadoContratada, setEstadoContratada] = useState('SP');
    const [cepContratada, setCepContratada] = useState('04750-030');

    const [materiais, setMateriais] = useState(`
        <ul>
            <ul>
                <li>Nível III-A</li>
                <li>Vidros Argus 19mm</li>
                <li>Placas de Udura 29 camadas</li>
                <li>A blindagem será feita em 20 dias corridos ou seja até a chegada do kit de vidro na fábrica você ficará usando o carro, (tempo médio de entrega do kit de vidro 35 a 40 dias corridos) ou seja só iremos retirar seu carro quando o kit de vidro estiver na nossa empresa</li>
            </ul>
        </ul>
    `);
    const [itensInclusos, setItensInclusos] = useState(`
        <ul>
            <ul>
                <li>Overlap de portas integrado aos vidros (SteelGlass)</li>
                <li>Blindagem de paralamas (Padrão)</li>
            </ul>
        </ul>
    `);

    const [garantias, setItensGarantias] = useState(`
        <ul>
            <ul>
                <li>Vidros 10 anos contra delaminação</li>
                <li>Placa de Udura 10 anos</li>
            </ul>
        </ul>
    `);

    const [posVenda, setPosVenda] = useState(`
        <ul>
            <ul>
                <li>Revisão corretiva até 6 meses gratuita</li>
                <li>Atendimento à domicílio, se necessário</li>
                <li>Fazemos toda documentação de exército, laudo Inmetro, laudo ECV, declaração de blindagem, todas as taxas e uma segunda documentação para inclusão de blindagem serão feitas a um custo definido no orçamento e serão pagos a parte na entrega do veículo blindado.</li>
            </ul>
        </ul>
    `);

    const [prazoEntrega, setPrazoEntrega] = useState(`
        <ul>
            <ul>
                <li>Contados à partir do veículo em nossa fábrica com autorização de blindagem do Exército Brasileiro, a instalação de blindagem terá o prazo de 60 dias corridos.</li>
            </ul>
        </ul>
    `);
    const [valorTotal, setValorTotal] = useState('');
    const [entrada, setEntrada] = useState('');
    const [parcelas, setParcelas] = useState('');

    const gatherFormData = () => {

        const especificacoesSelecionadas = especificacoes.filter(especificacao => checkboxState[especificacao.sku]).map(especificacao => {
            return {
                sku: especificacao.sku,
                valor: selectedOptions[especificacao.sku] || 1
            };
        });

        const balisticaSelecionada = balistica.map(row => ({
            id: row.id,
            status: checkboxStateBalistica[row.id] || false
        }));

        return {
            Veiculo: {
                marca: selectedMarca,
                modelo: selectedModelo,
                anoFabricacao: selectedAnoModelo,
                anoModelo: selectedAnoModeloDetalhes,
                categoriaId: categoriaId  // Inclui o ID da categoria aqui
            },
            Comprador: {
                nomeCliente: nomeCliente,
                cpfCliente: cpfCliente,
                rgCliente: rgCliente,
                dataNascimento: dataNascimento,
                telefone: telefone,
                email: email,
                endereco: endereco,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
                cep: cep
            },
            Vendedor: {
                nomeLocalVendedor: nomeLocalVendedor,
                enderecoVendedor: enderecoVendedor,
                bairroVendedor: bairroVendedor,
                cidadeVendedor: cidadeVendedor,
                estadoVendedor: estadoVendedor,
                cepVendedor: cepVendedor,
                telefoneVendedor: telefoneVendedor,
                emailVendedor: emailVendedor,
                siteVendedor: siteVendedor
            },
            Contratada: {
                cnpjContratada: cnpjContratada,
                nomeContratada: nomeContratada,
                enderecoContratada: enderecoContratada,
                bairroContratada: bairroContratada,
                cidadeContratada: cidadeContratada,
                estadoContratada: estadoContratada,
                cepContratada: cepContratada
            },
            Proposta: {
                materiais: materiais,
                itensInclusos: itensInclusos,
                garantias: garantias,
                posVenda: posVenda,
                balistica: balisticaSelecionada
            },
            Pagamento: {
                prazoEntrega: prazoEntrega,
                valorTotal: valorTotal,
                entrada: entrada,
                parcelas: parcelas,
                validadeProposta: selectedValidade
            },
            Blindagem: {
                tipoBlindagem: 5,
                tipoVidro: selectedVidro,
                Especificacoes: especificacoesSelecionadas,
                garantia: selectedGarantia
            }
        };
    };

    const [sending, setSending] = useState(false)


    const postPropostaBlindagem = async () => {
        const formData = gatherFormData(); // Pega os dados do formulário

        setSending(true); // Se tiver uma flag para mostrar o estado de envio

        try {
            let response;
            if (id) {
                response = await api.put(`/api/orcamento-digitado/${id}`, formData, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token
                    }
                });
            } else {
                response = await api.post('/api/orcamento-digitado', formData, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token
                    }
                });
            }

            // Verifica se a resposta do PUT é true para indicar sucesso
            if (response.status === 200 && response.data === true) {
                console.log('Proposta atualizada com sucesso:', response.data);
                handleAlert("Sucesso", "Proposta atualizada com sucesso.", "success");
                setTimeout(() => {
                    navigate(`/proposta`);
                }, 2000);

            } else if (response.status === 200 && typeof response.data === 'number') {
                console.log('Proposta criada com sucesso:', response.data);
                handleAlert("Sucesso", "Proposta criada com sucesso.", "success");
                setTimeout(() => {
                    navigate(`/proposta`);
                }, 2000);
            } else {
                handleAlert('Informações Insuficientes', response.data, 'danger');
            }
        } catch (error) {
            console.error('Erro ao enviar a proposta:', error);
            handleAlert('Informações Insuficientes', `Erro ao enviar a proposta`, 'danger');
        } finally {
            setSending(false); // Finaliza o estado de envio
        }
    };


    // Editar Dados

    const fetchProposta = async (id, token) => {
        try {
            const response = await api.get(`/api/orcamento-digitado/${id}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });

            if (response.data) {
                const proposta = response.data;
                console.log(proposta);

                // Preencha os campos do Comprador
                setNomeCliente(proposta.Comprador.nomeCliente || '');
                setCpfCliente(proposta.Comprador.cpfCliente || '');
                setRgCliente(proposta.Comprador.rgCliente || '');
                setDataNascimento(proposta.Comprador.dataNascimento || '');
                setTelefone(proposta.Comprador.telefone || '');
                setEmail(proposta.Comprador.email || '');
                setEndereco(proposta.Comprador.endereco || '');
                setBairro(proposta.Comprador.bairro || '');
                setCidade(proposta.Comprador.cidade || '');
                setEstado(proposta.Comprador.estado || '');
                setCep(proposta.Comprador.cep || '');

                // Preencha os campos do Vendedor
                setNomeLocalVendedor(proposta.Vendedor.nomeLocalVendedor || '');
                setEnderecoVendedor(proposta.Vendedor.enderecoVendedor || '');
                setBairroVendedor(proposta.Vendedor.bairroVendedor || '');
                setCidadeVendedor(proposta.Vendedor.cidadeVendedor || '');
                setEstadoVendedor(proposta.Vendedor.estadoVendedor || '');
                setCepVendedor(proposta.Vendedor.cepVendedor || '');
                setTelefoneVendedor(proposta.Vendedor.telefoneVendedor || '');
                setEmailVendedor(proposta.Vendedor.emailVendedor || '');
                setSiteVendedor(proposta.Vendedor.siteVendedor || '');

                // Preencha os campos da Contratada
                setCnpjContratada(proposta.Contratada.cnpjContratada || '');
                setNomeContratada(proposta.Contratada.nomeContratada || '');
                setEnderecoContratada(proposta.Contratada.enderecoContratada || '');
                setBairroContratada(proposta.Contratada.bairroContratada || '');
                setCidadeContratada(proposta.Contratada.cidadeContratada || '');
                setEstadoContratada(proposta.Contratada.estadoContratada || '');
                setCepContratada(proposta.Contratada.cepContratada || '');

                // Preencha os campos do Veículo
                setSelectedMarca(proposta.Veiculo.marca || null);
                setSelectedModelo(proposta.Veiculo.modelo || null);
                setSelectedAnoModelo(proposta.Veiculo.anoFabricacao || null);
                setSelectedAnoModeloDetalhes(proposta.Veiculo.anoModelo || null);

                // Carregue os modelos e anos conforme a marca e modelo da proposta
                if (proposta.Veiculo.marca) {
                    getModelos(proposta.Veiculo.marca);
                }
                if (proposta.Veiculo.modelo) {
                    getAnosModelo(proposta.Veiculo.modelo);
                }

                // Preencha os campos da blindagem
                const tipoBlindagem = proposta.Blindagem.tipoBlindagem;
                const blindagemCorrespondente = tiposBlindagem.find(b => b.value === tipoBlindagem);
                setSelectedBlindagem(blindagemCorrespondente ? blindagemCorrespondente.value : tiposBlindagem[0].value);

                setSelectedVidro(proposta.Blindagem.tipoVidro || '');
                setSelectedGarantia(proposta.Blindagem.garantia || '');

                // Preencha os campos do Pagamento
                setPrazoEntrega(proposta.Pagamento.prazoEntrega || '');
                setValorTotal(proposta.Pagamento.valorTotal || '');
                setEntrada(proposta.Pagamento.entrada || '');
                setParcelas(proposta.Pagamento.parcelas || null);
                setSelectedValidade(proposta.Pagamento.validadeProposta || null);

                // Preencha os campos de informações adicionais da Proposta
                setMateriais(proposta.Proposta.materias || '');
                setItensInclusos(proposta.Proposta.itensInclusos || '');
                setItensGarantias(proposta.Proposta.garantias || '');
                setPosVenda(proposta.Proposta.posVenda || '');

                // Preencha as especificações selecionadas
                proposta.Blindagem.Especificacoes.forEach(especificacao => {
                    setCheckboxState(prevState => ({
                        ...prevState,
                        [especificacao.sku]: true
                    }));
                    if (especificacao.valor > 1) {
                        setSelectedOptions(prevState => ({
                            ...prevState,
                            [especificacao.sku]: especificacao.valor
                        }));
                    }
                });
                // Preencha o estado dos checkboxes da balística
                setCheckboxStateBalistica(
                    proposta.Proposta.balistica.reduce((acc, item) => {
                        acc[item.id] = item.status;
                        return acc;
                    }, {})
                );
            }
        } catch (error) {
            console.error('Erro ao buscar a proposta:', error);
        }
    };

    useEffect(() => {
        if (id) {
            console.log('ID da proposta:', id);
            fetchProposta(id, token);
        } else {
            console.error('ID da proposta não encontrado');
        }
    }, [id, token]);


    //Quill

    const modules = {
        toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ],
    };


    // Nível de proteção


    const balistica = [
        { id: 1, text1: 'I', img1: balistica01, img2: armamento01, text2: '.22 LRHV Lead <br/> .38 Special RN Lead', text3: '15 - 16,5 cm <br/> 6 - 6,5 in <br/>  15 - 16,5 cm <br/> 6 - 6,5 in', text4: '320 +/- 12m/s <br/> 1050 +/- 40ft/s <br/> 259 +/- 15m/s <br/> 850 +/- 50ft/s', text5: '133,12 J <br/> 342,12 J', text6: '5' },
        { id: 2, text1: 'II - A', img1: balistica02, img2: armamento02, text2: '9mm FMJ <br/> .357 Mag JSP', text3: '10 - 12 cm <br/> 4 - 4,75 in <br/> 10 - 12 cm  <br/>4 - 4,75 in', text4: '332 +/- 12m/s <br/> 1050 +/- 40ft/s <br/> 281 +/- 15m/s <br/> 1250 +/- 50ft/s', text5: ' <br/> ', text6: '5' },
        { id: 3, text1: 'II', img1: balistica03, img2: armamento03, text2: '9mm FMJ <br/> .357 Mag JSP', text3: '10 - 12 cm <br/> 4 - 4,75 in  <br/> 15 - 16,5 cm <br/> 6 - 6,5 in', text4: '358 +/- 12m/s <br/> 1175 +/- 40ft/s <br/> 425 +/- 15m/s <br/> 1395 +/- 50ft/s', text5: '440,9 9J <br/> 740 J', text6: '5' },
        { id: 4, text1: 'III - A', img1: balistica04, img2: armamento04, text2: '24 - 26 cm <br/> 9,5 - 10,25 in <br/> 14 - 16 cm <br/> 5,5 - 6,25 in', text4: '426 +/- 15m/s <br/> 1400 +/- 50ft/s <br/> 426 +/- 15m/s <br/> 1400 +/- 50ft/s', text5: '512,66 J <br/> 921 J', text6: '5' },
        { id: 5, text1: 'III', img1: balistica05, img2: armamento05, text2: '7,62 x 51 FJ NB <br/> .308 Winchester FMJ', text3: '56 cm <br/> 22 in', text4: '838 +/- 15m/s <br/> 2750 +/- 50ft/s', text5: '3405 J', text6: '5' },
        { id: 6, text1: 'IV', img1: balistica06, img2: armamento06, text2: '30-06 AP', text3: '56 cm <br/> 22 in', text4: '868 +/- 15m/s <br/> 2850 +/- 50ft/s', text5: '4068,5 J', text6: '5' },
    ];

    const renderHTML = (htmlString) => {
        return <span dangerouslySetInnerHTML={{ __html: htmlString }} />;
    };


    const [checkboxStateBalistica, setCheckboxStateBalistica] = useState(
        balistica.reduce((acc, row) => {
            acc[row.id] = row.status;
            return acc;
        }, {})
    );

    // Função para manipular as mudanças nos checkboxes
    const handleCheckboxChangeBalistica = (rowId) => {
        setCheckboxStateBalistica(prevState => ({
            ...prevState,
            [rowId]: !prevState[rowId]
        }));
    };


    // Carregando dados  recebidos

    useEffect(() => {
        if (dadosProposta) {
            console.log("dadosProposta:", dadosProposta);
            setNomeCliente(dadosProposta.nome || '');
            setEmail(dadosProposta.email || '');
            setTelefone(dadosProposta.telefone || '');
            setValorTotal(dadosProposta.vl_final || '');


            if (dadosProposta.marcaId) {
                getModelos(dadosProposta.marcaId).then((loadedModelos) => {
                    const modeloExiste = loadedModelos.some(modelo => modelo.value === dadosProposta.modeloId);
                    if (modeloExiste) {
                        setSelectedModelo(dadosProposta.modeloId);
                        getAnosModelo(dadosProposta.modeloId).then(() => {
                            getBlindagemEspecificacoes(dadosProposta.modeloId).then((especificacoes) => {
                                console.log("especificacoess:", especificacoes); // Verifique se isso é exibido no console

                                if (dadosProposta.produtos && dadosProposta.produtos.length > 0) {
                                    const primeiroProduto = dadosProposta.produtos[0];
                                    setSelectedVidro(primeiroProduto.id);

                                    const garantia = determineGarantia(especificacoes, primeiroProduto.id);
                                    console.log("garantia:", garantia); // Verifique se isso é exibido no console
                                    setSelectedGarantia(garantia);
                                }
                            }).catch(error => {
                                console.error("Erro ao buscar especificações de blindagem:", error);
                            });
                        });
                    } else {
                        setSelectedModelo(null);
                    }
                }).catch(error => {
                    console.error("Erro ao carregar modelos:", error);
                });
            }
            setSelectedMarca(dadosProposta.marcaId || null);
        }
    }, [dadosProposta]);



    // Efeito para mapear os produtos após as especificações serem carregadas
    useEffect(() => {
        if (especificacoes.length > 0 && dadosProposta) {
            dadosProposta.produtos.forEach((produtoProposta) => {
                especificacoes.forEach((especificacao) => {
                    // Verificar se produtos é um objeto com arrays dentro
                    if (typeof especificacao.produtos === 'object') {
                        Object.values(especificacao.produtos).forEach((produtoArray) => {
                            // Verificar se produtoArray é realmente um array
                            const produtos = Array.isArray(produtoArray) ? produtoArray : [produtoArray];
                            produtos.forEach((produtoEspecificacao) => {
                                if (produtoEspecificacao.id === produtoProposta.id) {
                                    // Marcar checkbox correspondente
                                    setCheckboxState(prevState => ({
                                        ...prevState,
                                        [especificacao.sku]: true
                                    }));

                                    // Selecionar a opção no dropdown, se houver múltiplos produtos
                                    if (produtos.length > 1) {
                                        setSelectedOptions(prevState => ({
                                            ...prevState,
                                            [especificacao.sku]: produtoProposta.id
                                        }));
                                    }
                                }
                            });
                        });
                    }
                });
            });
        }
    }, [especificacoes, dadosProposta]);

    return (
        <>
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle} />
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <div className="Content Proposta">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                                <div className='HeaderDashboard'>
                                    <a href='/dashboard' className='logo'><img src={logo} /></a>
                                    <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                                </div>
                            </>
                                : <>
                                    <div className='HeaderLeads'>
                                        <i><Icon icon="fa-solid:car" ></Icon></i>
                                        <h2>Propostas <br />
                                            <span>Gerencie informações e detalhes dos veículos cadastrados</span></h2>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NavVeiculos'>
                                <ul>
                                    <li>
                                        <a className={`${intVeiculo ? 'active' : ''}`} onClick={() => handleNavigationClick('veiculo')}>
                                            <i><Icon className="icons" icon="wpf:security-checked" /></i>
                                            <span>BLINDAGEM<br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${intComprador ? 'active' : ''}`} onClick={() => handleNavigationClick('comprador')}>
                                            <i><Icon className="icons" icon="fluent:person-16-filled" /></i>
                                            <span>CONTRATANTE<br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${intVendedor ? 'active' : ''}`} onClick={() => handleNavigationClick('vendedor')}>
                                            <i><Icon className="icons" icon="material-symbols:handshake" /></i>
                                            <span>VENDEDOR<br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${intInformacoes ? 'active' : ''}`} onClick={() => handleNavigationClick('informacoes')}>
                                            <i><Icon className="icons" icon="material-symbols:info" /></i>
                                            <span>INFORMAÇÕES<br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${intPagamento ? 'active' : ''}`} onClick={() => handleNavigationClick('pagamento')}>
                                            <i><Icon className="icons" icon="solar:chat-round-money-bold" /></i>
                                            <span>PRAZOS E <br />PAGAMENTOS</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {intVeiculo &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>DADOS DO VEÍCULO</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Marca </span>
                                                            <div className={invalidFields.includes('marca') ? 'wrongField' : ''}>
                                                                <Dropdown
                                                                    filter
                                                                    value={selectedMarca}
                                                                    onChange={handleMarcaChange}
                                                                    options={marcas}
                                                                    optionLabel="label"
                                                                    placeholder="Marca"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Modelo</span>
                                                            <div className={invalidFields.includes('modelo') ? 'wrongField' : ''}>
                                                                <Dropdown
                                                                    emptyMessage="Sem resultado"
                                                                    filter
                                                                    value={selectedModelo}
                                                                    onChange={handleModeloChange}
                                                                    options={modelos}
                                                                    optionLabel="label"
                                                                    placeholder="Escolha o Modelo"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha o ano de fabricação</span>
                                                            <div className={invalidFields.includes('anoFabricacao') ? 'wrongField' : ''}>
                                                                <Dropdown
                                                                    emptyMessage="Sem resultado"
                                                                    filter
                                                                    value={selectedAnoModelo}
                                                                    onChange={handleAnoFabricaChange}  // Chama a função handleAnoFabricaChange
                                                                    options={anosModelo}  // Usa os anos obtidos da API
                                                                    optionLabel="label"
                                                                    placeholder="Ano de Fabricação"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha o ano do modelo</span>
                                                            <div className={invalidFields.includes('anoModelo') ? 'wrongField' : ''}>
                                                                <Dropdown
                                                                    emptyMessage="Sem resultado"
                                                                    filter
                                                                    value={selectedAnoModeloDetalhes}
                                                                    onChange={(e) => setSelectedAnoModeloDetalhes(e.value)}
                                                                    options={anoFabricacao}  // Usa os anos baseados no ano de fabricação
                                                                    optionLabel="label"
                                                                    placeholder="Ano do Modelo"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {selectedModelo && (<>
                                        <Row className='justify-content-center'>
                                            <Col xs={12} sm={12} md={8} lg={8}>
                                                <div className='HeaderNovo'>
                                                    <h2>DADOS DA BLINDAGEM</h2>
                                                    <div className='statusMultiplos'></div>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={8}>
                                                <Form.Group className="FormNovo">
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6} lg={4}>
                                                            <Form.Label className='Label'>
                                                                <span>Tipo de Blindagem </span>
                                                                <div className="">
                                                                    <Dropdown disabled={true}
                                                                        value={selectedBlindagem}
                                                                        onChange={(e) => setSelectedBlindagem(e.value)}
                                                                        options={tiposBlindagem}
                                                                        optionLabel="label"
                                                                        placeholder="Tipo de Blindagem"
                                                                        className='Dropdown'
                                                                    />
                                                                </div>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={4}>
                                                            <Form.Label className='Label'>
                                                                <span>Tipo de Vidro</span>
                                                                <div className={invalidFields.includes('vidro') ? 'wrongField' : ''}>
                                                                    <Dropdown
                                                                        value={selectedVidro}
                                                                        onChange={(e) => {
                                                                            setSelectedVidro(e.value);
                                                                            handleVidroChange(e.value); // Chama a função aqui após selecionar o vidro
                                                                        }}
                                                                        options={vidros}
                                                                        optionLabel="label"
                                                                        placeholder="Escolha o Tipo de Vidro"
                                                                        className='Dropdown'
                                                                    />
                                                                </div>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={4}>
                                                            <Form.Label className='Label'>
                                                                <span>Garantia</span>
                                                                <div className="">
                                                                    <Dropdown
                                                                        disabled={true}
                                                                        value={selectedGarantia}
                                                                        options={[{ label: '5 anos', value: '5 anos' }, { label: '10 anos', value: '10 anos' }]}
                                                                        onChange={(e) => setSelectedGarantia(e.value)}
                                                                        placeholder="Garantia do Vidro"
                                                                        className='Dropdown'
                                                                    />
                                                                </div>
                                                            </Form.Label>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className='justify-content-center'>
                                            <Col xs={12} sm={12} md={8} lg={8}>
                                                <div className='HeaderNovo'>
                                                    <h2>Opcionais da blindagem</h2>
                                                    <div className='statusMultiplos'></div>
                                                </div>
                                            </Col>

                                            <Col xs={12} sm={12} md={8} lg={8}>
                                                <Form.Group className="FormNovo">
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            {/* <Form.Label className='Label'> */}
                                                            <div className="FormControl">
                                                                <div>
                                                                    <span>Opcionais da blindagem</span>
                                                                    {especificacoes.filter(especificacao => especificacao.sku !== 'tipo_vidro').map((especificacao) => (
                                                                        <React.Fragment key={especificacao.sku}>
                                                                            <Form.Group className="FormNovo">
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    label={especificacao.titulo}
                                                                                    onChange={() => handleCheckboxChange(especificacao.sku)}
                                                                                    checked={checkboxState[especificacao.sku]}
                                                                                />
                                                                                {checkboxState[especificacao.sku] && especificacao.produtos && especificacao.produtos.length > 1 && (
                                                                                    <Dropdown
                                                                                        value={selectedOptions[especificacao.sku] || ''}
                                                                                        options={especificacao.produtos.map(produto => ({ label: produto.titulo, value: produto.id }))}
                                                                                        onChange={(e) => handleOptionChange(especificacao.sku, e.value)}
                                                                                        placeholder={`Escolha a opção de ${especificacao.titulo}`}
                                                                                        className='Dropdown'
                                                                                    />
                                                                                )}
                                                                            </Form.Group>

                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {/* </Form.Label> */}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>)}
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {!id && (
                                                    <>
                                                        <Button className='Btns ButtonPrimaryLight' onClick={handleContinuar}>Continuar</Button>
                                                    </>
                                                )}

                                                {id && (
                                                    <Button className='Btns ButtonPrimaryLight' onClick={postPropostaBlindagem}>
                                                        Atualizar
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intComprador &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>INFORMAÇÕES DO CONTRATANTE</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className={`FormControl Full ${invalidFields.includes('nomeCliente') ? 'wrongField' : ''}`}>
                                                                <Form.Control className='Inputs' type="text" placeholder="Nome do cliente" value={nomeCliente} onChange={(e) => setNomeCliente(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="CPF" value={cpfCliente} onChange={(e) => setCpfCliente(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="RG" value={rgCliente} onChange={(e) => setRgCliente(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    as={InputMask}
                                                                    mask="99/99/9999"
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="Data de Nascimento"
                                                                    value={dataNascimento}
                                                                    onChange={(e) => setDataNascimento(e.target.value)}
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className={`FormControl Full ${invalidFields.includes('telefone') ? 'wrongField' : ''}`}>
                                                                <Form.Control className='Inputs' type="text" placeholder="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className={`FormControl Full ${invalidFields.includes('email') ? 'wrongField' : ''}`}>
                                                                <Form.Control className='Inputs' type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={8}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Endereço" value={endereco} onChange={(e) => setEndereco(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Cidade" value={cidade} onChange={(e) => setCidade(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Estado" value={estado} onChange={(e) => setEstado(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="CEP" value={cep} onChange={(e) => setCep(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {!id && (
                                                    <>
                                                        <Button className='Btns ButtonSecondary' onClick={handleBack}>Voltar</Button>
                                                        <Button className='Btns ButtonPrimaryLight' onClick={handleContinuar}>Continuar</Button>
                                                    </>
                                                )}

                                                {id && (
                                                    <Button className='Btns ButtonPrimaryLight' onClick={postPropostaBlindagem}>
                                                        Atualizar
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intVendedor &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>INFORMAÇÕES DA VENDEDORA</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Nome do Local" value={nomeLocalVendedor} onChange={(e) => setNomeLocalVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Endereço" value={enderecoVendedor} onChange={(e) => setEnderecoVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Bairro" value={bairroVendedor} onChange={(e) => setBairroVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Cidade" value={cidadeVendedor} onChange={(e) => setCidadeVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Estado" value={estadoVendedor} onChange={(e) => setEstadoVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="CEP" value={cepVendedor} onChange={(e) => setCepVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Telefone" value={telefoneVendedor} onChange={(e) => setTelefoneVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="E-mail" value={emailVendedor} onChange={(e) => setEmailVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Site" value={siteVendedor} onChange={(e) => setSiteVendedor(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>INFORMAÇÕES DA CONTRATADA</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    as={InputMask}
                                                                    mask="99.999.999/9999-99"
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="CNPJ"
                                                                    value={cnpjContratada}
                                                                    onChange={(e) => setCnpjContratada(e.target.value)}
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={8}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Nome" value={nomeContratada} onChange={(e) => setNomeContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={8}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Endereço" value={enderecoContratada} onChange={(e) => setEnderecoContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Bairro" value={bairroContratada} onChange={(e) => setBairroContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Cidade" value={cidadeContratada} onChange={(e) => setCidadeContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Estado" value={estadoContratada} onChange={(e) => setEstadoContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={4}>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="CEP" value={cepContratada} onChange={(e) => setCepContratada(e.target.value)} />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {!id && (
                                                    <>
                                                        <Button className='Btns ButtonSecondary' onClick={handleBack}>Voltar</Button>
                                                        <Button className='Btns ButtonPrimaryLight' onClick={handleContinuar}>Continuar</Button>
                                                    </>
                                                )}

                                                {id && (
                                                    <Button className='Btns ButtonPrimaryLight' onClick={postPropostaBlindagem}>
                                                        Atualizar
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intInformacoes &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>INFORMAÇÕES DA PROPOSTA</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='diferenciais'>
                                                        <Form.Label className='Label'>
                                                            <span>Matériais</span>
                                                            <ReactQuill
                                                                value={materiais}
                                                                className='quill'
                                                                modules={modules} // Certifique-se de definir os módulos conforme necessário
                                                                placeholder="Digite os materiais aqui..."
                                                                dangerouslySetInnerHTML={{ __html: materiais }}
                                                                onChange={(value) => setMateriais(value)}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='diferenciais'>
                                                        <Form.Label className='Label'>
                                                            <span>Itens Inclusos</span>
                                                            <ReactQuill
                                                                value={itensInclusos}
                                                                className='quill'
                                                                modules={modules}
                                                                placeholder="Digite os itens inclusos aqui..."
                                                                dangerouslySetInnerHTML={{ __html: itensInclusos }}
                                                                onChange={(value) => setItensInclusos(value)}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='diferenciais'>
                                                        <Form.Label className='Label'>
                                                            <span>Garantias</span>
                                                            <ReactQuill
                                                                value={garantias}
                                                                className='quill'
                                                                modules={modules}
                                                                placeholder="Digite as garantias aqui..."
                                                                onChange={(value) => setItensGarantias(value)}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='diferenciais'>
                                                        <Form.Label className='Label'>
                                                            <span>PÓS VENDA</span>
                                                            <ReactQuill
                                                                value={posVenda}
                                                                className='quill'
                                                                modules={modules}
                                                                placeholder="Digite as informações de pós-venda aqui..."
                                                                onChange={(value) => setPosVenda(value)}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='protecao'>
                                                        <Form.Label className='Label'>
                                                            <span>NÍVEL DE PROTEÇÃO BALÍSTICA</span>
                                                        </Form.Label>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Selecionar</th>
                                                                    <th>Níveis de Blindagem</th>
                                                                    <th>Projéteis</th>
                                                                    <th>Armamento</th>
                                                                    <th>Tipo de Munição</th>
                                                                    <th>Comprimento do Calibre</th>
                                                                    <th>Velocidade  Referência</th>
                                                                    <th>Energia Cinética</th>
                                                                    <th>Disparos por painel</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {balistica.map((row) => (
                                                                    <tr key={row.id}>
                                                                        <td className='switch'>
                                                                            <Form.Check
                                                                                className='Label'
                                                                                type="switch"
                                                                                label={checkboxStateBalistica[row.id] ? "Sim" : "Não"}
                                                                                checked={checkboxStateBalistica[row.id] || false}
                                                                                onChange={() => handleCheckboxChangeBalistica(row.id)}
                                                                            />
                                                                        </td>
                                                                        <td>{row.text1}</td>
                                                                        <td><img src={row.img1} alt={`Imagem ${row.id} - 1`} width="50" /></td>
                                                                        <td><img src={row.img2} alt={`Imagem ${row.id} - 2`} width="50" /></td>
                                                                        <td>{renderHTML(row.text2)}</td>
                                                                        <td>{renderHTML(row.text3)}</td>
                                                                        <td>{renderHTML(row.text4)}</td>
                                                                        <td>{renderHTML(row.text5)}</td>
                                                                        <td>{row.text6}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {!id && (
                                                    <>
                                                        <Button className='Btns ButtonSecondary' onClick={handleBack}>Voltar</Button>
                                                        <Button className='Btns ButtonPrimaryLight' onClick={handleContinuar}>Continuar</Button>
                                                    </>
                                                )}

                                                {id && (
                                                    <Button className='Btns ButtonPrimaryLight' onClick={postPropostaBlindagem}>
                                                        Atualizar
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intPagamento &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>Prazos e pagamento</h2>
                                                <div className='statusMultiplos'></div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={9} lg={12} className='diferenciais'>
                                                        <Form.Label className='Label'>
                                                            <span>PRAZO DE ENTREGA</span>
                                                            <ReactQuill
                                                                value={prazoEntrega}
                                                                className='quill'
                                                                modules={modules}
                                                                placeholder="Digite o prazo de entrega aqui..."
                                                                onChange={(value) => setPrazoEntrega(value)}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={3}>
                                                        <Form.Label className='Label'>
                                                            <span>Valor total</span>
                                                            <div className="FormControl Full">
                                                                <ReaisInput value={valorTotal} handleChange={handlePriceChange} placeholder="Valor Total" />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={3}>
                                                        <Form.Label className='Label'>
                                                            <span>Entrada %</span>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="Entrada"
                                                                    value={entrada}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value.replace('%', '');
                                                                        setEntrada(`${value}%`);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={3}>
                                                        <Form.Label className='Label'>
                                                            <span>Parcelas</span>
                                                            <div className="FormControl Full">
                                                                <Dropdown
                                                                    value={parcelas}
                                                                    options={parcelasOptions}
                                                                    onChange={(e) => setParcelas(e.value)}
                                                                    placeholder="Escolha a forma de pagamento"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={3}>
                                                        <Form.Label className='Label'>
                                                            <span>VALIDADE DA PROPOSTA</span>
                                                            <div className="">
                                                                <Dropdown
                                                                    value={selectedValidade}
                                                                    options={validadeOpcoes}
                                                                    optionLabel="label"
                                                                    onChange={(e) => setSelectedValidade(e.value)}
                                                                    placeholder="Escolha a validade"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                <Button className='Btns ButtonPrimaryLight' onClick={postPropostaBlindagem}>
                                                    {id ? 'Atualizar' : 'Salvar'}
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Proposta;